var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "设备维修记录",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: {
                  "head-cancel": _vm.handleHeadGoback,
                  "head-save": function ($event) {
                    return _vm.headSave(1)
                  },
                  "head-submit": function ($event) {
                    return _vm.headSave(2)
                  },
                },
              }),
              _c(
                "avue-form",
                {
                  ref: "formLayout",
                  staticStyle: { padding: "10px" },
                  attrs: { option: _vm.formOptions },
                  model: {
                    value: _vm.dataForm,
                    callback: function ($$v) {
                      _vm.dataForm = $$v
                    },
                    expression: "dataForm",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "recordCode" },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            maxlength: "100",
                            disabled: _vm.type != "add",
                          },
                          model: {
                            value: _vm.dataForm.recordCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "recordCode", $$v)
                            },
                            expression: "dataForm.recordCode",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "append" }, slot: "append" },
                            [
                              _vm._v(
                                "\n                            自动生成\n                            "
                              ),
                              _c("el-switch", {
                                attrs: { disabled: _vm.type != "add" },
                                on: { change: _vm.handleSwidth },
                                model: {
                                  value: _vm.generate,
                                  callback: function ($$v) {
                                    _vm.generate = $$v
                                  },
                                  expression: "generate",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "equipmentCode" },
                    [
                      _c("el-input", {
                        ref: "equipmentCode",
                        attrs: {
                          disabled: _vm.type == "view",
                          "suffix-icon": "el-icon-search",
                        },
                        on: { focus: _vm.changeEquipment },
                        model: {
                          value: _vm.dataForm.equipmentCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "equipmentCode", $$v)
                          },
                          expression: "dataForm.equipmentCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "userName" },
                    [
                      _c("el-input", {
                        ref: "userName",
                        attrs: {
                          disabled: _vm.type == "view",
                          "suffix-icon": "el-icon-search",
                        },
                        on: { focus: _vm.changeUser },
                        model: {
                          value: _vm.dataForm.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "userName", $$v)
                          },
                          expression: "dataForm.userName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "人员选择",
                    visible: _vm.userVisible,
                    width: "80%",
                    top: "8vh",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.userVisible = $event
                    },
                  },
                },
                [
                  _c("UserDetpDialog", {
                    ref: "UserDetpDialog",
                    on: { "select-data": _vm.selectData },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("equipmentDialog", {
        ref: "equipmentDialog",
        on: { callback: _vm.getEquipment },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }